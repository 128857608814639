<template>
  <div class="schedule-wrapper terminal">
    <Button class="scroll-button left"
            label=""
            @change="swipe('left')">
      <i class="icon icon-arrow-left"></i>
    </Button>
    <div class="content-wrapper" ref="content">
      <Button class="round-wrapper"
              label=""
              ref="round-content"
              v-for="(round, index) in offer"
              :disabled="isScheduleDisabled"
              :key="index"
              :class="{ brand: activeIndex === index }"
              :style="{width: `${roundWrapperWidth}px`}"
              @change="toggle(round, index)">
        <div class="round-time">{{ formatTime(round.startDate.date) }}</div>
        <div class="round-number">{{ `${roundLabel} ${round.eventIdToday}` }}</div>
      </Button>
    </div>
    <Button class="scroll-button right"
            label=""
            @change="swipe">
      <i class="icon icon-arrow-right"></i>
    </Button>
  </div>
</template>

<script>
import { Schedule } from '@nsftx/games-sdk-js';

export default {
  name: 'ScheduleTerminal',
  extends: Schedule,
};
</script>

<style lang="scss" scoped>
.schedule-wrapper {
  width: 100%;
  cursor: pointer;
  background-color: var(--background-1);
  border-radius: 2px;
  -webkit-tap-highlight-color: transparent;
  .scroll-button {
    max-width: 40px;
    width: 100%;
    color: var(--text-primary-2);
    margin: 0 1px;
    &.left {
      float: left;
    }
    &.right {
      float: right;
    }
  }
  &.terminal {
    margin: 0px;
    height: 88px;
    padding: 8px 0;
    .scroll-button {
      font-size: 24px;
      height: 70px;
    }
    .content-wrapper {
      height: 70px;
      .round-wrapper {
        height: 100%;
        margin: 0px 2px;

        .round-time {
          font-size: 24px;
          line-height: 24px;
        }
        .round-number {
          font-size: 20px;
          line-height: 20px;
        }
      }
    }
  }
}
.content-wrapper {
  overflow-x: hidden;
  white-space: nowrap;
  float: left;
  width: calc(100% - calc(40px * 2) -
    calc(1px * 4));
  text-align: left;
  cursor: default;
  .round-wrapper {
    display: inline-block;
    width: 112px;
    margin: 0 1px;
    font-size: 12px;
    .round-time {
      font-weight: bold;
    }
    .round-number {
      opacity: 0.6;
    }
    &.brand {
      color: var(--custom-text-color);
    }
  }
  button {
    font-family: 'Roboto', 'sans-serif';
    line-height: 14px;
  }
}
@media (min-width: 306px) and (max-width: 660px) {
  .content-wrapper {
    overflow: overlay;
  }
}
@media all and (max-width: 420px) {
  .schedule-wrapper {
    .scroll-button.left,
    .scroll-button.right {
      display: none;
    }
    .content-wrapper {
      width: 100%;
    }
  }
}
@media all and (max-width: 1025px) {
  .schedule-wrapper {
    &.terminal {
      width: calc(100% - 80px);
    }
  }
}
@media all and (max-width: 1680px) {
  .schedule-wrapper {
    margin: 4px;
    &.terminal {
      margin: 0px;
      padding: 9px 0px;
      height: 72px;
      .scroll-button {
        font-size: 24px;
        height: 54px;
      }
      .content-wrapper {
        height: 54px;
        .round-wrapper {
          height: 100%;
          margin: 0px 2px;
          .round-time {
            font-size: 16px;
            line-height: 16px;
          }
          .round-number {
            font-size: 16px;
            line-height: 16px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="races-betting-area-a">
    <button ref="simulateClickElement" style="display: none;"></button>
    <Tabs :tabs="tabs"
          :activeTab="activeTab"
          v-bind="$attrs"
          @change="updateTab"></Tabs>
     <component v-bind:is="component.component"
               v-on="$listeners"
               v-bind="$attrs"
               @change="addBet"/>
  </div>
</template>

<script>
//  import { mapGetters } from 'vuex';
import RacesBettingAreaA from '@/components/RacesBettingAreaA';
import Tabs from '@/components/Terminal/TabsTerminal';

export default {
  name: 'RacesBettingAreaATerminal',
  extends: RacesBettingAreaA,
  inheritAttrs: false,
  components: {
    Tabs,
  },
};
</script>

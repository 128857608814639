<template>
  <div class="tricast-buttons-grid" :class="{ horses: isHorses }">
    <div class="header">
      <div class="header-item"
           v-for="(item, key) in headerItems"
           :key="key">
        {{item.title}}
      </div>
    </div>
    <div class="buttons-row"
         v-for="(row, index) in $attrs.racers"
         :key="index">
      <div class="label">
        <RacerLabelTerminal :ordinalNumber="row"
                    :color="$attrs.colors[index]"
                    :height="getlabelHeight()"
                    orientation="right">
        </RacerLabelTerminal>
      </div>
      <Button v-for="(racer, key) in tricastRows[index]"
              :key="key"
              :disabled="racer.disabled || $attrs.bettingDisabled || allBettingDisabled"
              @change="selectOdd(racer)"
              :variation="racer.selected ? 'brand' : 'default'"
              label="">
              <i :class="racer.label"></i>
      </Button>
    </div>
    <div class="add-bet-wrapper">
      <Button label="Add to betslip"
              :variation="checkIsValid ? 'default' : 'brand'"
              :disabled="checkIsValid"
              @change="handleChange">
      </Button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TricastGrid from '@/components/TricastGrid';
import RacerLabelTerminal from '@/components/Terminal/RacerLabelTerminal';

export default {
  name: 'TricastGridTerminal',
  extends: TricastGrid,
  components: {
    RacerLabelTerminal,
  },
  computed: {
    ...mapGetters([
      'config',
      'allBettingDisabled',
    ]),
    isHorses() {
      return this.config.productName === 'VirtualHorseRaces' && window.innerHeight <= 768;
    },
  },
  methods: {
    getlabelHeight() {
      if (window.innerWidth < 1900) {
        return 54;
      }
      return 72;
    },
  },
};
</script>

<style lang="scss" scoped>
  .tricast-buttons-grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background-color: var(--card);
    padding: 0 8px 8px 8px;
    &.horses {
      height: 435px;
      overflow-y: scroll;
      flex-wrap: nowrap;
    }
    .header {
      font-family: 'Roboto', sans-serif;
      display: flex;
      justify-content: space-around;
      font-size: 16px;
      color: var(--text-primary-2);
      margin-left: 17%;
      padding: 8px;
      margin-top: 16px;
      .header-item {
        user-select: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
      }
    }

    .buttons-row {
      display: flex;

      .label {
        width: 17%;
        margin: 1px;
      }

      .button {
        font-family: 'Roboto', sans-serif;
        flex-grow: 1;
        width: 0;
        margin: 1px;
        height: 54px;
        font-size: 22px;
        &.disabled {
          user-select: none;
          pointer-events: none;
        }
      }
    }
    .add-bet-wrapper {
      padding: 8px 0px 8px 0px;
      width: 100%;
      .button {
        font-family: Roboto;
        height: 36px;
        font-size: 18px;
      }
    }
  }
  @media all and (min-width: 1900px) {
    .tricast-buttons-grid {
      .header {
        font-size: 20px;
      }

      .buttons-row {
        .button {
          height: 72px;
          font-size: 28px;
          &.disabled {
            user-select: none;
            pointer-events: none;
          }
        }
      }
      .add-bet-wrapper {
        padding: 8px 0px 8px 0px;
        width: 100%;
        .button {
          font-family: Roboto;
          height: 35px;
          font-size: 18px;
        }
      }
    }
  }
</style>

<template>
  <div class="h2h-wrapper">
    <div class="header">
    </div>
    <div class="row-wrapper" >
      <div class="label-wrapper" :class="{ mobile: isMobile }">
        <div class="header"></div>
        <RacerLabelTerminal v-for="(row, index) in headToHeadOdds"
                    class="label"
                    :key="index"
                    :ordinalNumber="row[0].racers[0]"
                    :color="$attrs.colors[row[0].racers[0] - 1]"
                    :height="getlabelHeight()"
                    orientation="right">
        </RacerLabelTerminal>
      </div>
      <div class="info-wrapper">
        <div class="header"></div>
        <div class="info" v-for="(racer, index) in headToHeadOdds"
          :key="index">
          {{ getLabel(racer) }}
        </div>
      </div>
      <div class="label-wrapper" :class="{ mobile: isMobile }">
        <div class="header"></div>
        <RacerLabelTerminal v-for="(row, index) in headToHeadOdds"
                    class="label"
                    :key="index"
                    :ordinalNumber="row[0].racers[1]"
                    :color="$attrs.colors[row[0].racers[1] - 1]"
                    :height="getlabelHeight()"
                    orientation="right">
        </RacerLabelTerminal>
      </div>
      <div class="button-wrapper">
        <div class="header">1</div>
        <Button v-for="(row, index) in headToHeadOdds"
          :key="index"
          :disabled="$attrs.bettingDisabled ||
            headToHeadOdds[index][0].value === 0 || allBettingDisabled"
          :variation="isActive(headToHeadOdds[index][0]) ? 'brand' : 'default'"
          :label="row[0].value === 0 ? '-' : row[0].value.toFixed(2)"
          @change="[selectOdd(row[0])]">
        </Button>
      </div>
      <div class="button-wrapper">
        <div class="header">2</div>
        <Button v-for="(row, index) in headToHeadOdds"
          :key="index"
          :disabled="$attrs.bettingDisabled ||
            headToHeadOdds[index][1].value === 0 || allBettingDisabled"
          :variation="isActive(headToHeadOdds[index][1]) ? 'brand' : 'default'"
          :label="row[1].value === 0 ? '-' : row[1].value.toFixed(2)"
          @change="[selectOdd(row[1])]">
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HeadToHeadGrid from '@/components/HeadToHeadGrid';
import RacerLabelTerminal from '@/components/Terminal/RacerLabelTerminal';

export default {
  name: 'HeadToHeadGridTerminal',
  extends: HeadToHeadGrid,
  components: {
    RacerLabelTerminal,
  },
  computed: {
    ...mapGetters([
      'allBettingDisabled',
    ]),
  },
  methods: {
    getlabelHeight() {
      if (window.innerWidth < 1900) {
        return 54;
      }
      return 72;
    },
  },
};
</script>

<style lang="scss" scoped>
.h2h-wrapper {
  background-color: var(--card);
  .header {
    color: var(--text-primary-1);
    opacity: 0.6;
    height: 14px;
    margin-bottom: 7px;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
  }
  .row-wrapper {
    padding: 0px 8px 8px 8px;
    display: flex;
    width: 100%;
    .label-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 100px;
      width: 100px;
      &.mobile {
        max-width: 50px;
      }
      .label {
        margin: 1px;
      }
    }
    .info-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      text-align: center;
      .info {
        font-family: 'Roboto';
        font-size: 16px;
        color: var(--text-primary-1);
        background-color: var(--card-section);
        height: 54px;
        line-height: 54px;
        margin: 1px;
        //  max-width: 268px;
        width: 100%;
      }
    }
    .button-wrapper {
      display: flex;
      flex-direction: column;
      max-width: 170px;
      width: 100%;
      margin: 1px;
      button {
        margin: 1px;
        height: 54px;
        font-size: 18px;
      }
    }
  }
}
@media all and (min-width: 1900px) {
  .h2h-wrapper {
    .header {
      height: 20px;
      font-size: 20px;
      line-height: 20px;
    }
    .row-wrapper {
      .label-wrapper {
        max-width: 163px;
        width: 100%;
      }
      .info-wrapper {
        max-width: 531px;
        .info {
          font-size: 24px;
          height: 72px;
          line-height: 72px;
        }
      }
      .button-wrapper {
        display: flex;
        flex-direction: column;
        max-width: 280px;
        width: 100%;
        margin: 1px;
        button {
          margin: 1px;
          height: 72px;
          font-size: 28px;
        }
      }
    }
}
}
</style>

<template>
  <div class="racer-label-wrapper">
    <div class="racer-label"
         :style="racerLabelStyle">
      <span>{{ ordinalNumber }}</span>
    </div>
  </div>
</template>

<script>
import RacerLabel from '@/components/RacerLabel';

export default {
  name: 'RacerLabelTerminal',
  extends: RacerLabel,
  props: {
    height: {
      type: [String, Number],
      default: '50',
    },
  },
};
</script>

<style scoped lang="scss">
.racer-label-wrapper {
  background: var(--card-bg);
}
.racer-label {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-primary-1);
  font-size: 16px;
  font-weight: 500;
  border-radius: 2px;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
@media all and (min-width: 1900px) {
  .racer-label {
    font-size: 24px;
  }
}
</style>

<template>
  <div class="forecast-grid-wrapper" :class="{ horses: isHorses }">
    <div class="top-racer-labels">
      <span class="top-left-label">
        <Button label=""
          :variation="reverseForecastActive ? 'brand' : 'default'"
          @change="toggleReverse">
          <span><i class="icon icon-sort"></i></span>
      </Button>
      </span>
      <div class="colored-labels" v-if="!isMobile">
        <RacerLabelTerminal
          v-for="(racer, index) in $attrs.racers"
          :ordinalNumber="racer"
          :color="$attrs.colors[index]"
          :height="getlabelHeight()"
          orientation="bottom"
          :key="index">
        </RacerLabelTerminal>
      </div>
    </div>
    <div class="bet-wrapper">
      <div class="left-racer-labels" v-if="!isMobile">
        <RacerLabelTerminal
          v-for="(racer, index) in $attrs.racers"
          :ordinalNumber="racer"
          :color="$attrs.colors[index]"
          :height="getlabelHeight()"
          orientation="right"
          :key="index">
        </RacerLabelTerminal>
      </div>
      <div class="forecast-bets-wrapper" v-if="!reverseForecastActive">
        <div class="forecast-bets"
          v-for="(row, key) in $attrs.forecastOdds"
          :key="key">
           <Button
             v-for="(odd, index) in row"
             :key="index"
             :class="{disabled: odd.empty || $attrs.bettingDisabled}"
             :disabled="odd.empty || $attrs.bettingDisabled || allBettingDisabled"
             :variation="isActive(odd) && !odd.reverse && odd.betTypeId === 10 ?
              'brand' : 'default'"
             @change="selectOdd(getButton(key, index))"
             :label="odd.empty ? getLabel : formatOdd(odd.value)">
             <div class="label-wrapper" v-if="isMobile">
              <RacerLabelTerminal v-if="odd.empty"
                :ordinalNumber="index"
                :color="$attrs.colors[index - 1]"
                :height="getlabelHeight()"
                orientation="right">
              </RacerLabelTerminal>
             </div>
          </Button>
        </div>
      </div>
      <div class="forecast-bets-wrapper" v-if="reverseForecastActive">
        <div class="forecast-bets"
             v-for="(row, key) in $attrs.reverseForecastOdds"
             :key="key">
          <Button
            v-for="(odd, index) in row"
            :key="index"
            :class="{disabled: odd.empty || $attrs.bettingDisabled}"
            :disabled="odd.empty || $attrs.bettingDisabled"
            :variation="isActive(odd) && odd.reverse && odd.betTypeId === 12
              ? 'brand' : 'default'"
            @change="selectOdd(getButton(key, index))"
            :label="odd.empty ? getLabel : formatOdd(odd.value)">
            <div class="label-wrapper" v-if="isMobile">
              <RacerLabelTerminal v-if="odd.empty"
                :ordinalNumber="index"
                :color="$attrs.colors[index - 1]"
                :height="getlabelHeight()"
                orientation="right">
              </RacerLabelTerminal>
             </div>
          </Button>
        </div>
      </div>
    </div>
    <!-- <div class="reverse-wrapper">
      <Button :label="reverseForecastLabel"
              :variation="reverseForecastActive ? 'brand' : 'default'"
              @change="toggleReverse">
      </Button>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ForecastGrid from '@/components/ForecastGrid';
import RacerLabelTerminal from '@/components/Terminal/RacerLabelTerminal';

export default {
  name: 'ForecastGridTerminal',
  extends: ForecastGrid,
  components: {
    RacerLabelTerminal,
  },
  computed: {
    ...mapGetters([
      'config',
      'allBettingDisabled',
    ]),
    isHorses() {
      return this.config.productName === 'VirtualHorseRaces' && window.innerHeight <= 768;
    },
  },
  methods: {
    getlabelHeight() {
      if (window.innerWidth < 1900) {
        return 54;
      }
      return 72;
    },
  },
};
</script>

<style scoped lang="scss">
@font-face {
  font-family: "NFont";
  src: url("https://assets.nsoft-cdn.com/public/gravity/icons/nfont.eot");
  src: url("https://assets.nsoft-cdn.com/public/gravity/icons/nfont.eot?#iefix") format("embeded-opentype"),
  url("https://assets.nsoft-cdn.com/public/gravity/icons/nfont.woff") format("woff"),
  url("https://assets.nsoft-cdn.com/public/gravity/icons/nfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
.n-i {
  font-family: "NFont";
  font-style: normal;
  font-weight: bold;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-sort {
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: 50%;
  font-size: 24px;
  transform: translate(-50%, -50%);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.icon-sort:before {
  content: "\ea13";
  transform: rotate(90deg);
}
.forecast-grid-wrapper {
  background-color: var(--card);
  width: 100%;
  padding: 16px 8px 8px 8px;
  &.horses {
    height: 435px;
    overflow-y: scroll;
    flex-wrap: nowrap;
  }
  .reverse-wrapper {
    padding: 8px 0px 8px 0px;
    width: 100%;
    .button {
      height: 72px;
      font-family: Roboto;
      font-size: 28px;
    }
  }
  .top-racer-labels {
    display: flex;
    .top-left-label {
      width: 20%;
      //  visibility: hidden;
      .button {
        width: calc(100% - 2px);
        top: 1px;
        height: 72px;
        span {
          position: relative;
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
    .colored-labels {
      display: flex;
      flex-direction: row;
      width: 100%;
      .racer-label-wrapper {
        width: 100%;
        margin: 1px;
      }
    }
  }
  .left-racer-labels {
    display: flex;
    flex-direction: column;
    width: 20%;
    div {
      margin: 1px;
    }
  }
  .bet-wrapper {
    display: flex;
    .forecast-bets-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      .forecast-bets {
        display: flex;
        button {
          flex-grow: 1;
          width: 0;
          height: 72px;
          font-size: 28px;
          margin: 1px;
          padding: 0;
          &.brand.disabled {
            background-color: var(--button-disabled-bg-color);
            color: var(--button-disabled-color);
          }
        }
      }
    }
  }
  .reverse-forecast-wrapper {
    font-family: "Roboto", sans-serif;
    text-align: right;
    padding-right: 5px;
    padding-bottom: 5px;
    height: 35px;
  }
}
@media all and (max-width: 1880px) {
  .icon-sort {
    font-size: 16px;
  }
  .forecast-grid-wrapper {
  .reverse-wrapper {
    .button {
      height: 54px;
      font-size: 22px;
    }
  }
  .bet-wrapper {
    .forecast-bets-wrapper {
      .forecast-bets {
        button {
          height: 54px;
          font-size: 22px;
        }
      }
    }
  }
  .top-racer-labels {
    .top-left-label {
      .button {
        height: 54px;
      }
    }
  }
  .reverse-forecast-wrapper {
    font-family: "Roboto", sans-serif;
    text-align: right;
    padding-right: 5px;
    padding-bottom: 5px;
    height: 35px;
  }
}

}
</style>

<template>
  <div class="racecard-buttons-grid" :class="{ horses: isHorses }">
    <div class="header">
      <div class="header-item"
           v-for="(item, key) in $attrs.raceCardHeaderItems"
           :key="key">
        {{item.title}}
      </div>
    </div>
    <div class="buttons-row"
         v-for="(row, index) in $attrs.raceCardRows"
         :key="index">
      <div class="label">
        <RacerLabelTerminal :ordinalNumber="row"
                    :color="$attrs.colors[index]"
                    :height="getlabelHeight()"
                    orientation="right">
        </RacerLabelTerminal>
      </div>
      <Button v-for="(column, key) in $attrs.raceCardColumns"
              :key="key"
              :disabled="$attrs.bettingDisabled || allBettingDisabled"
              :class="{disabled: getButton(index, key).value === 0 || $attrs.bettingDisabled}"
              @change="selectOdd(getButton(index, key), column)"
              :variation="isActive(getButton(index, key)) ? 'brand' : 'default'"
              :label="getButton(index, key).value === 0 ? '-' :
                String(formatOdd(getButton(index, key).value))">
      </Button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RaceCardGrid from '@/components/RaceCardGrid';
import RacerLabelTerminal from '@/components/Terminal/RacerLabelTerminal';

export default {
  name: 'RaceCardGridTerminal',
  extends: RaceCardGrid,
  components: {
    RacerLabelTerminal,
  },
  computed: {
    ...mapGetters([
      'config',
      'allBettingDisabled',
    ]),
    isHorses() {
      return this.config.productName === 'VirtualHorseRaces' && window.innerHeight <= 768;
    },
  },
  methods: {
    getlabelHeight() {
      if (window.innerWidth < 1900) {
        return 54;
      }
      return 72;
    },
  },
};
</script>

<style lang="scss" scoped>
  .racecard-buttons-grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background-color: var(--card);
    padding: 0 8px 8px 8px;
    &.horses {
      height: 435px;
      overflow-y: scroll;
      flex-wrap: nowrap;
    }
    .header {
      font-family: 'Roboto', sans-serif;
      display: flex;
      justify-content: space-around;
      font-size: 20px;
      color: var(--text-primary-2);
      margin-left: 17%;
      padding: 8px;
      margin-top: 16px;
      .header-item {
        user-select: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
      }
    }

    .buttons-row {
      display: flex;

      .label {
        width: 17%;
        margin: 1px;
      }

      .button {
        font-family: 'Roboto', sans-serif;
        flex-grow: 1;
        width: 0;
        margin: 1px;
        height: 72px;
        font-size: 28px;
        &.disabled {
          user-select: none;
          pointer-events: none;
        }
      }
    }
  }

  @media (min-width: 660px) {
    .racecard-buttons-grid {
      .header {
        margin-left: 180px;
    }
      .buttons-row {
        .label {
          width: 180px;
        }
      }
    }
  }
  @media all and (max-width: 1880px) {
    .racecard-buttons-grid {
      .header {
        font-size: 16px;
      }

    .buttons-row {
      .button {
        height: 54px;
        font-size: 22px;
      }
    }
  }
}
</style>

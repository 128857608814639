<template>
  <div class="float-betslip-button"
    @click="showBetslip">
    <div class="float-betslip-button-content center-xy">
      <i class="n-i n-i-betslip" :style="{color: ticketLength ?
        'var(--secondary)': 'var(--text-primary-3)'}"></i>
      <div v-if="ticketLength" :style="{backgroundColor: 'var(--secondary)'}">
        <span v-if="ticketLength"
          class="center-xy">
          {{ticketLength}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'FloatBetslipButton',
  data() {
    return {
      integration: true,
    };
  },
  computed: {
    ...mapGetters([
      'bets',
      'activeBetslip',
      'pickedNumbers',
      'resultsActive',
      'secondary',
      'shadow_1',
      'secondary_text',
      'screenSize',
    ]),
    ...mapGetters('gamesBetslip', [
      'ticket',
    ]),
    ticketLength() {
      return this.ticket?.length;
    },
  },
  methods: {
    ...mapActions([
      'setActiveBetslip',
      'setActiveSearchInput',
    ]),
    showBetslip() {
      this.$root.$emit('ShowBetslipTerminal');
    },
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "NFont";
  src: url("https://assets.nsoft-cdn.com/public/gravity/icons/nfont.eot");
  src: url("https://assets.nsoft-cdn.com/public/gravity/icons/nfont.eot?#iefix") format("embeded-opentype"),
  url("https://assets.nsoft-cdn.com/public/gravity/icons/nfont.woff") format("woff"),
  url("https://assets.nsoft-cdn.com/public/gravity/icons/nfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
.n-i {
  font-family: "NFont";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.n-i-betslip:before {
  content: "\e640";
}
.float-betslip-button {
  margin: 8px;
  position: relative;
  height: 50px;
  width: 80px;
  border-radius: 2px;
  background-color: var(--button);
  .float-betslip-button-content {
    color: var(--text-secondary-1);
      .n-i-betslip {
        font-size: 24px;
      }
      > div {
        height: 20px;
        font-size: 14px;
        right: -10px;
        top: -10px;
        width: 20px;
      }
    }

  .float-betslip-button-content {
    color: var(--text-primary-2);
    position: absolute;
    align-items: center;
    display: flex;
    right: 25px;
    height: 24px;
    top: 12px;
    right: 25px;
    justify-content: center;
    text-align: center;
    width: 24px;
    div {
      position: absolute;
      border-radius: 50%;
      height: 18px;
      font-size: 12px;
      font-weight: bold;
      right: -5px;
      text-align: center;
      top: -5px;
      width: 18px;
      background-color: var(--secondary);
      span {
        position: absolute;
        top: 2px;
        right: 3px;
        color: var(--text-secondary-1);
      }
    }
  }
}
</style>

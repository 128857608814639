<template>
  <div class="betting-area">
    <div class="round-shedule-wrapper">
      <Schedule :offer="offer"
                :roundLabel="roundLabel"
                ref="schedule"
                @selectedRound="selectRound">
      </Schedule>
      <FloatBetslipButton class="float-button"/>
    </div>
    <component :is="bettingComponent"
               :tabs="bettingComponentsTabs"
               :raceCardRows="racers"
               :raceCardColumns="raceCardColumns"
               :tricastColumns="tricastColumns"
               :activeButtons="tickets"
               :activeReverseBets="revTickets"
               direction="vertical"
               :colors="labelColors"
               :racers="racers"
               :raceCardHeaderItems="raceCardHeaderItems"
               :raceCardOdds="raceCardOdds"
               :forecastOdds="forecastOdds"
               :reverseForecastOdds="reverseForecastOdds"
               :headToHeadOdds="headToHeadOdds"
               :bettingDisabled="bettingDisabled"
               :activeRound="activeRound"
               @tab-changed="setDrawDetailsHeight"
               @selectRaceCard="selectRacecardOdd"
               @selectForecast="selectForecastOdd"
               @selectH2H="selectH2HOdd"
               @selectTricastOdd="selectTricastOdd">
    </component>
  </div>
</template>

<script>
import { find } from 'lodash';
import FloatBetslipButton from '@/components/Terminal/FloatBetslipButton';
import Schedule from '@/components/Terminal/ScheduleTerminal';
import BettingArea from '@/components/BettingArea';
import RacesBettingAreaATerminal from '@/components/RacesBettingAreaATerminal';
import HeadToHeadGridTerminal from './HeadToHeadGridTerminal';
import RaceCardGridTerminal from './RaceCardGridTerminal';
import ForecastGridTerminal from './ForecastGridTerminal';
import TricastGridTerminal from './TricastGridTerminal';

export default {
  name: 'BettingAreaTerminal',
  extends: BettingArea,
  components: {
    Schedule,
    FloatBetslipButton,
    RacesBettingAreaATerminal,
    HeadToHeadGridTerminal,
    RaceCardGridTerminal,
    ForecastGridTerminal,
    TricastGridTerminal,
  },
  data() {
    return {
      bettingComponentsTabs: [],
    };
  },
  methods: {
    setHeadToHeadTab() {
      if (this.offer[0].odds.headToHead) {
        const h2h = {
          id: 2,
          key: 2,
          name: 'HeadToHeadGrid',
          label: this.translations.vms_head_to_head,
          component: HeadToHeadGridTerminal,
          disabled: false,
        };
        this.bettingComponentsTabs.push(h2h);
      }
    },
  },
  computed: {
    components() {
      const tricastConfig = find(this.config.bets, {
        id: 11,
      });
      const defaultBets = [
        {
          id: 0,
          key: 0,
          name: 'RaceCardGrid',
          label: this.translations.greyhound_race_card,
          component: RaceCardGridTerminal,
          disabled: false,
        },
        {
          id: 1,
          key: 1,
          name: 'ForecastGrid',
          label: this.translations.greyhound_forecast,
          component: ForecastGridTerminal,
          disabled: false,
        },
      ];
      const tricast = {
        id: 2,
        key: 2,
        name: 'TricastGrid',
        label: this.translations.greyhound_tricast,
        component: TricastGridTerminal,
        disabled: false,
      };
      if (tricastConfig && tricastConfig.value) {
        defaultBets.push(tricast);
      }
      return defaultBets;
    },
  },
};
</script>

<style lang="scss">
.betting-area {
  display: flex;
  flex-direction: column;
  .round-shedule-wrapper {
    display: flex;
    width: 100%;
    .float-button {
      display: none;
    }
  }
  .tabs {
    .tab-button {
      max-width: unset;
    }
  }
}
  @media all and (max-width: 1050px) {
    .betting-area {
      margin: 0;
      .round-shedule-wrapper {
        height: 72px;
        background-color: var(--background-1);
        display: flex;
        justify-content: center;
        align-items: center;
        .float-button {
          display: flex;
          height: 54px;
          width: 80px;
        }
      }
    }
  }
</style>

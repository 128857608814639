<template>
  <div class="tabs">
    <Tab
      v-for="(tab, index) in tabs"
      :is-selected="activeTab.id === index && !tab.disabled"
      :is-disabled="tab.disabled"
      :key="index"
      :label="tab.label"
      :buttonType="buttonType"
      :type="tab.type"
      :icon="tab.icon"
      :counter="tab.counter"
      :isCounterActive="tab.isCounterActive"
      @click="selectTab(index)"
    >
      <div class="counter" v-if="tab.isCounterActive && tab.counter > 0">
        <span>{{ tab.counter }}</span>
      </div>
    </Tab>
    <!-- <div class="empty"></div> -->
  </div>
</template>

<script>
import { Tabs } from '@nsftx/games-sdk-js';
import Tab from '@/components/Terminal/TabTerminal';

export default {
  name: 'TabsTerminal',
  extends: Tabs,
  components: {
    Tab,
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
  justify-content: flex-start;
  height: 54px;
  .empty {
    background-color: var(--card);
    border-bottom: 2px solid rgba(#ffffff, 0.05);
  }
  .counter.animate {
    animation-name: emphasize;
    animation-duration: 0.5s;
    animation-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  }
}
@media all and (min-width: 1910px) {
  .tabs {
    height: 72px;
  }
}
</style>
